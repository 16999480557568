<template>
  <div>
    <Overlay :overlay="overlay" />
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <v-row class="mx-3" no-gutters>
      <v-col cols="12" md="3">
        <v-card height="165px" class="elevation-3 mr-2">
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <v-card-text align="left">
                <v-icon size="75" style="margin-top: -10px" color="primary">
                  mdi-account-star
                </v-icon>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="8">
              <v-card-text align="center">
                <div class="mb-3 black--text" style="font-size: 20px">
                  CUSTOMERS
                </div>
                <div
                  class="my-0 mx-0 black--text"
                  style="font-size: 30px; font-weight: bold"
                >
                  {{
                    dashboardCount.customer != undefined
                      ? dashboardCount.customer.organisation
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row class="mt-n4" no-gutters>
            <v-col cols="12" md="12">
              <v-divider class="mx-3"></v-divider>
            </v-col>
          </v-row>
          <v-row class="mx-1" no-gutters>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">USERS</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.customer != undefined
                      ? dashboardCount.customer.users
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">LOCATIONS</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.customer != undefined
                      ? dashboardCount.customer.location
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">SWIPES</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.customer != undefined
                      ? dashboardCount.customer.swipes
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <v-divider class="mx-3"></v-divider>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <v-card-text class="mt-2 pa-0" align="center">
                <span style="font-size: 18px"
                  ><strong> 500+ credits </strong></span
                >
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card height="165px" class="elevation-3 mr-2">
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <v-card-text align="left">
                <v-icon size="75" style="margin-top: -15px" color="primary">
                  mdi-account-settings
                </v-icon>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="8">
              <v-card-text align="center">
                <div class="mb-3 black--text" style="font-size: 21px">
                  PROSPECTS
                </div>
                <div
                  class="my-0 mx-0 black--text"
                  style="font-size: 30px; font-weight: bold"
                >
                  {{
                    dashboardCount.prospect != undefined
                      ? dashboardCount.prospect.organisation
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row class="mt-n3" no-gutters>
            <v-col cols="12" md="12">
              <v-divider class="mx-3"></v-divider>
            </v-col>
          </v-row>
          <v-row class="mx-1" no-gutters>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">USERS</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.prospect != undefined
                      ? dashboardCount.prospect.users
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">LOCATIONS</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.prospect != undefined
                      ? dashboardCount.prospect.location
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">SWIPES</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.prospect != undefined
                      ? dashboardCount.prospect.swipes
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <v-divider class="mx-3"></v-divider>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <v-card-text class="pa-0 mt-2" align="center">
                <span class="mx-2" style="font-size: 18px"
                  ><strong> 250 to 500 credits</strong>
                </span>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card height="165px" class="elevation-3 mr-2">
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <v-card-text align="left">
                <v-icon size="75" style="margin-top: -15px" color="primary">
                  mdi-account-group
                </v-icon>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="8">
              <v-card-text align="center">
                <div class="mb-3 black--text" style="font-size: 20px">
                  ACTIVATED
                </div>
                <div
                  class="my-0 mx-0 black--text"
                  style="font-size: 30px; font-weight: bold"
                >
                  {{
                    dashboardCount.activatedon != undefined
                      ? dashboardCount.activatedon.organisation
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row class="mt-n3" no-gutters>
            <v-col cols="12" md="12">
              <v-divider class="mx-3"></v-divider>
            </v-col>
          </v-row>
          <v-row class="mx-1" no-gutters>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">USERS</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.activatedon != undefined
                      ? dashboardCount.activatedon.users
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">LOCATIONS</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.activatedon != undefined
                      ? dashboardCount.activatedon.location
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">SWIPES</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.activatedon != undefined
                      ? dashboardCount.activatedon.swipes
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <v-divider class="mx-3"></v-divider>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <v-card-text class="pa-0 mt-2" align="center">
                <span
                  class="mx-2 my-0 py-0"
                  style="font-size: 18px; text-align: justify"
                >
                  <strong> 100 to 250 credits</strong>
                </span>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card height="165px" class="elevation-3 mr-2">
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <v-card-text align="left">
                <v-icon size="75" style="margin-top: -15px" color="primary">
                  mdi-account-alert
                </v-icon>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="8">
              <v-card-text align="center">
                <div class="mb-3 black--text" style="font-size: 20px">
                  ONBOARDED
                </div>
                <div
                  class="my-0 mx-0 black--text"
                  style="font-size: 30px; font-weight: bold"
                >
                  {{
                    dashboardCount.onboard != undefined
                      ? dashboardCount.onboard.organisation
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row class="mt-n3" no-gutters>
            <v-col cols="12" md="12">
              <v-divider class="mx-3"></v-divider>
            </v-col>
          </v-row>
          <v-row class="mx-1" no-gutters>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">USERS</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.onboard != undefined
                      ? dashboardCount.onboard.users
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">LOCATIONS</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.onboard != undefined
                      ? dashboardCount.onboard.location
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">SWIPES</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.onboard != undefined
                      ? dashboardCount.onboard.swipes
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <v-divider class="mx-3"></v-divider>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <v-card-text class="pa-0 mt-2" align="center">
                <span
                  class="mx-2 my-0 py-0"
                  style="font-size: 18px; text-align: justify"
                >
                  <strong> 10 to 100 credits</strong>
                </span>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- <v-col cols="12" md="4">
        <v-card height="165px" class="elevation-3 mr-2">
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <v-card-text align="left">
                <v-icon size="75" style="margin-top: -15px" color="primary">
                  mdi-account-group
                </v-icon>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="8">
              <v-card-text align="center">
                <div class="mb-3 black--text" style="font-size: 20px">
                  TRIAL
                </div>
                <div
                  class="my-0 mx-0 black--text"
                  style="font-size: 30px; font-weight: bold"
                >
                  {{
                    dashboardCount.trail != undefined
                      ? dashboardCount.trail.organisation
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row class="mt-n3" no-gutters>
            <v-col cols="12" md="12">
              <v-divider class="mx-3"></v-divider>
            </v-col>
          </v-row>
          <v-row class="mx-1" no-gutters>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">USERS</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.trail != undefined
                      ? dashboardCount.trail.users
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">LOCATIONS</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.trail != undefined
                      ? dashboardCount.trail.location
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">SWIPES</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.trail != undefined
                      ? dashboardCount.trail.swipes
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <v-divider class="mx-3"></v-divider>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <v-card-text class="pa-0 mt-2" align="center">
                <span
                  class="mx-2 my-0 py-0"
                  style="font-size: 18px; text-align: justify"
                >
                  <strong> {{ dashboardCount.trail.trail_map }}</strong>
                </span>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col> -->
    </v-row>
    <v-row no-gutters class="ma-3 mt-2">
      <v-col cols="12" md="12">
        <v-card class="elevation-0 mr-2">
          <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="12" md="5">
              <v-toolbar flat dense class="ml-n2 mt-3">
                <span style="font-size: 17px">
                  <h2>Consumed Credits for last 30 days</h2>
                </span>
              </v-toolbar>
            </v-col>
            <v-spacer />
            <v-col cols="12" md="2">
              <v-select
                dense
                outlined
                label="Filter By"
                v-model="creditsType"
                :items="creditsTypeItems"
                class="mt-4 ml-n5"
                color="custom_secondary"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                dense
                outlined
                label="Filter By"
                v-model="filterCredits"
                :items="filterCreditsItems"
                class="mt-4 ml-n3 mr-3"
                color="custom_secondary"
                :disabled="creditsType == 'monthly_credits'"
              ></v-select>
            </v-col>
            <v-col cols="12" md="2">
              <v-select
                dense
                outlined
                label="Filter By"
                v-model="organisation_credits"
                :items="organisation_status_items"
                class="mt-4 ml-n1 mx-1"
                color="custom_secondary"
                :disabled="creditsType == 'monthly_credits'"
              ></v-select>
            </v-col>
          </v-row>
          <div v-if="switchChart1" class="ma-3 mt-n5 mb-5">
            <BarChart
              :height="400"
              :chartdata="datacollectionBar"
              :options="optionsgraphBar"
              :count="count"
            />
          </div>
          <div v-if="switchChart2" class="ma-3 mt-n5 mb-5">
            <BarChart
              :height="400"
              :chartdata="datacollectionBar2"
              :options="optionsgraphBar2"
              :count="count"
            />
          </div>
          <div v-if="switchChart3">
            <BarChart
              :chartdata="datacollectionBar3"
              :options="optionsgraphBar3"
              :count="count"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Overlay from "@/components/Overlay.vue";
import SnackBar from "@/components/SnackBar.vue";
import { API, graphqlOperation } from "aws-amplify";
import {
  SalesPrzncePortalDashboard,
  DashboardDailyScansSales,
  GetDashboardMonthlyScansSales,
} from "@/graphql/queries.js";
import BarChart from "@/components/Charts/BarChart.js";

export default {
  props: {
    userObj: Object,
  },
  components: {
    Overlay,
    SnackBar,
    BarChart,
  },
  data: () => ({
    overlay: false,
    count: 0,
    SnackBarComponent: {},
    dashboardCount: {},
    creditsType: "daily_credits",
    creditsTypeItems: [
      { text: "DAILY CREDITS", value: "daily_credits" },
      { text: "MONTHLY CREDITS", value: "monthly_credits" },
    ],
    filterCredits: "count_credits",
    filterCreditsItems: [
      { text: "CREDITS CONSUMED", value: "count_credits" },
      { text: "AVERAGE CREDITS", value: "avg_credits" },
    ],
    organisation_credits: "CUSTOMER",
    organisation_status_items: [
      { text: "ALL", value: "ALL" },
      { text: "CUSTOMERS", value: "CUSTOMER" },
      { text: "PROSPECTS", value: "PROSPECT" },
      { text: "ACTIVATED", value: "ACTIVATED" },
      { text: "ONBOARDED", value: "ONBOARD" },
    ],
    switchChart1: true,
    switchChart2: false,
    switchChart3: false,
    datacollectionBar: {
      labels: [],
      datasets: [
        {
          data: [],
          label: "Total Credits",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
        {
          data: [],
          label: "QR Scan",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
        {
          data: [],
          label: "Reswipe",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
        {
          data: [],
          label: "Whatsapp",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
        {
          data: [],
          label: "Data Correction",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
        {
          data: [],
          label: "Proxy",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
      ],
    },
    optionsgraphBar: {
      yAxes: [
        {
          ticks: {
            beginAtZero: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      responsive: true,
      maintainAspectRatio: false,
    },
    datacollectionBar2: {
      labels: [],
      datasets: [
        {
          data: [],
          label: "Average Swipes",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
      ],
    },
    optionsgraphBar2: {
      yAxes: [
        {
          ticks: {
            beginAtZero: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      scales: {
        xAxes: [
          {
            barPercentage: 0.3,
          },
        ],
      },
      responsive: true,
      maintainAspectRatio: false,
    },
    datacollectionBar3: {
      labels: [],
      datasets: [
        {
          data: [],
          label: "Monthly Swipes",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
      ],
    },
    optionsgraphBar3: {
      yAxes: [
        {
          ticks: {
            beginAtZero: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            barPercentage: 0.2,
          },
        ],
      },
      responsive: true,
      maintainAspectRatio: false,
    },
  }),
  created() {
    this.overlay = true;
    this.count = 0;
    this.get_dashboard_list(this.userObj);
    this.get_global_scan_list_customer("CUSTOMER");
  },
  watch: {
    creditsType(val) {
      if (val == "monthly_credits") {
        this.Overlay = true;
        this.count = 0;
        this.getMonthlyScans();
        this.switchChart1 = false;
        this.switchChart2 = false;
        this.switchChart3 = true;
      } else {
        this.Overlay = true;
        this.count = 0;
        this.get_dashboard_list();
        this.get_global_scan_list_customer("CUSTOMER");
        this.filterCredits = "count_credits";
        this.organisation_credits = "CUSTOMER";
        this.switchChart1 = true;
        this.switchChart2 = false;
        this.switchChart3 = false;
      }
    },
    filterCredits(val) {
      if (val == "avg_credits") {
        this.DailyCountCustomer = [];
        this.DailyCount = [];
        this.datacollectionBar2.labels = [];
        this.switchChart1 = false;
        this.switchChart2 = true;
        this.overlay = true;
        this.count = 0;
        this.get_global_scan_list_customer2(this.organisation_credits);
      } else {
        this.DailyCountCustomer = [];
        this.DailyCount = [];
        this.datacollectionBar.labels = [];
        this.switchChart1 = true;
        this.switchChart2 = false;
        this.overlay = true;
        this.count = 0;
        this.get_global_scan_list_customer(this.organisation_credits);
      }
    },
    organisation_credits(val) {
      this.DailyCountCustomer = [];
      this.DailyCount = [];
      this.overlay = true;
      this.count = 0;
      if (this.filterCredits == "count_credits") {
        this.datacollectionBar.labels = [];
        this.get_global_scan_list_customer(val);
      } else if (this.filterCredits == "avg_credits") {
        this.datacollectionBar2.labels = [];
        this.get_global_scan_list_customer2(val);
      }
    },
  },
  methods: {
    async get_dashboard_list(val) {
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(SalesPrzncePortalDashboard, {
            input: {
              rep_id: val.rep_id,
            },
          })
        );
        this.dashboardCount = JSON.parse(
          result.data.SalesPrzncePortalDashboard
        );
        // console.log(this.dashboardCount);
        // this.overlay = false;
      } catch (err) {
        console.log("error", err);
        this.overlay = false;
      }
    },
    async get_global_scan_list_customer(val) {
      this.DailyCount = [];
      this.DailyCountCustomer = [];
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(DashboardDailyScansSales, {
            input: {
              rep_id: this.userObj.rep_id,
              organisation_type: val,
              year: new Date().getFullYear(),
            },
          })
        );
        this.DailyCountCustomer = JSON.parse(
          result.data.DashboardDailyScansSales
        );
        var totlColor = [];
        var totlArray = [];
        let cameraArray = [];
        var cameraColor = [];
        var reswipeArray = [];
        var reswipeColor = [];
        var datacorrectionArray = [];
        var datacorrectionColor = [];
        var whatsappColor = [];
        var whatsappArray = [];
        var proxyArray = [];
        var proxyColor = [];
        this.datacollectionBar.datasets[0].data = [];
        this.datacollectionBar.datasets[1].data = [];
        this.datacollectionBar.datasets[2].data = [];
        this.datacollectionBar.datasets[3].data = [];
        this.datacollectionBar.datasets[4].data = [];
        this.datacollectionBar.datasets[5].data = [];
        this.datacollectionBar.labels = [];
        for (let i = 0; i < this.DailyCountCustomer.length; i++) {
          totlArray.push(this.DailyCountCustomer[i].total_swipes);
          reswipeArray.push(this.DailyCountCustomer[i].reswipe);
          cameraArray.push(this.DailyCountCustomer[i].camera);
          datacorrectionArray.push(this.DailyCountCustomer[i].datacorrection);
          whatsappArray.push(this.DailyCountCustomer[i].whatsapp);
          proxyArray.push(this.DailyCountCustomer[i].proxy);
          totlColor.push("#800000");
          cameraColor.push("#3cba9f");
          reswipeColor.push("#CD5C5C");
          datacorrectionColor.push("#0000FF");
          whatsappColor.push("#800080");
          proxyColor.push("#FFFF00");

          this.datacollectionBar.datasets[0].data = totlArray;
          this.datacollectionBar.datasets[0].borderColor = totlColor;
          this.datacollectionBar.datasets[0].backgroundColor = totlColor;
          this.datacollectionBar.datasets[1].data = cameraArray;
          this.datacollectionBar.datasets[1].backgroundColor = cameraColor;
          this.datacollectionBar.datasets[1].borderColor = cameraColor;
          this.datacollectionBar.datasets[2].data = reswipeArray;
          this.datacollectionBar.datasets[2].backgroundColor = reswipeColor;
          this.datacollectionBar.datasets[2].borderColor = reswipeColor;
          this.datacollectionBar.datasets[3].data = whatsappArray;
          this.datacollectionBar.datasets[3].backgroundColor = whatsappColor;
          this.datacollectionBar.datasets[3].borderColor = whatsappColor;
          this.datacollectionBar.datasets[4].data = datacorrectionArray;
          this.datacollectionBar.datasets[4].backgroundColor =
            datacorrectionColor;
          this.datacollectionBar.datasets[4].borderColor = datacorrectionColor;
          this.datacollectionBar.datasets[5].data = proxyArray;
          this.datacollectionBar.datasets[5].backgroundColor = proxyColor;
          this.datacollectionBar.datasets[5].borderColor = proxyColor;
          this.datacollectionBar.labels.push(this.DailyCountCustomer[i].date);
        }
        this.count = 1;
        this.$forceUpdate();
        this.overlay = false;
      } catch (err) {
        console.log("error", err);
        this.overlay = false;
        this.datacollectionBar.datasets[0].data = [];
      }
    },
    async get_global_scan_list_customer2(val) {
      this.DailyCount = [];
      this.DailyCountCustomer = [];
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(DashboardDailyScansSales, {
            input: {
              rep_id: this.userObj.rep_id,
              organisation_type: val,
              year: new Date().getFullYear(),
            },
          })
        );

        this.DailyCountCustomer = JSON.parse(
          result.data.DashboardDailyScansSales
        );
        var AvgArray = [];
        var AvgColor = [];
        this.datacollectionBar2.datasets[0].data = [];
        for (let i = 0; i < this.DailyCountCustomer.length; i++) {
          AvgArray.push(
            Number(this.DailyCountCustomer[i].averageSwipes).toFixed(2)
          );
          AvgColor.push("#00C897");
          this.datacollectionBar2.datasets[0].data = AvgArray;
          this.datacollectionBar2.datasets[0].backgroundColor = AvgColor;
          this.datacollectionBar2.datasets[0].borderColor = AvgColor;
          this.datacollectionBar2.labels.push(this.DailyCountCustomer[i].date);
        }
        this.count = 1;
        this.$forceUpdate();
        this.overlay = false;
      } catch (err) {
        console.log("error", err);
        this.datacollectionBar2.datasets[0].data = [];
        this.overlay = false;
      }
    },
    async getMonthlyScans() {
      this.overlay = true;
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(GetDashboardMonthlyScansSales, {
            input: {
              rep_id: this.userObj.rep_id,
            },
          })
        );
        let MonthlyScans = JSON.parse(
          result.data.GetDashboardMonthlyScansSales
        );
        var MonthlyArray = [];
        var MonthlyColor = [];
        this.datacollectionBar3.datasets[0].data = [];
        this.datacollectionBar3.labels = [];
        for (let i = 0; i < MonthlyScans.length; i++) {
          MonthlyArray.push(MonthlyScans[i].no_of_swipes);
          MonthlyColor.push("#FC4F4F");
          this.datacollectionBar3.datasets[0].data = MonthlyArray;
          this.datacollectionBar3.datasets[0].backgroundColor = MonthlyColor;
          this.datacollectionBar3.datasets[0].borderColor = MonthlyColor;
          this.datacollectionBar3.labels.push(MonthlyScans[i].dynamo_month);
        }
        this.count = 1;
        this.$forceUpdate();
        this.overlay = false;
      } catch (err) {
        console.log(err);
        this.overlay = false;
      }
    },
  },
};
</script>

<style>
.v-select__selection {
  width: 100%;
  justify-content: center;
}
</style>
