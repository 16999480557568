<template>
  <div>
    <Overlay :overlay="overlay" />
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <v-row class="mx-3" no-gutters>
      <v-col cols="12" md="4">
        <v-card height="165px" class="elevation-3 mr-2">
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <v-card-text align="left">
                <v-icon size="75" style="margin-top: -10px" color="primary">
                  mdi-account-star
                </v-icon>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="8">
              <v-card-text align="center">
                <div class="mb-3 black--text" style="font-size: 20px">
                  CUSTOMERS
                </div>
                <div
                  class="my-0 mx-0 black--text"
                  style="font-size: 30px; font-weight: bold"
                >
                  {{
                    dashboardCount.customer != undefined
                      ? dashboardCount.customer.organisation
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row class="mt-n4" no-gutters>
            <v-col cols="12" md="12">
              <v-divider class="mx-3"></v-divider>
            </v-col>
          </v-row>
          <v-row class="mx-1" no-gutters>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">USERS</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.customer != undefined
                      ? dashboardCount.customer.users
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">LOCATIONS</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.customer != undefined
                      ? dashboardCount.customer.location
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">SWIPES</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.customer != undefined
                      ? dashboardCount.customer.swipes
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <v-divider class="mx-3"></v-divider>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <v-card-text class="mt-2 pa-0" align="center">
                <span style="font-size: 18px"
                  ><strong>
                    {{ dashboardCount.customer.customer_map }}
                  </strong></span
                >
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card height="165px" class="elevation-3 mr-2">
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <v-card-text align="left">
                <v-icon size="75" style="margin-top: -15px" color="primary">
                  mdi-account-settings
                </v-icon>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="8">
              <v-card-text align="center">
                <div class="mb-3 black--text" style="font-size: 21px">
                  PROSPECTS
                </div>
                <div
                  class="my-0 mx-0 black--text"
                  style="font-size: 30px; font-weight: bold"
                >
                  {{
                    dashboardCount.prospect != undefined
                      ? dashboardCount.prospect.organisation
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row class="mt-n3" no-gutters>
            <v-col cols="12" md="12">
              <v-divider class="mx-3"></v-divider>
            </v-col>
          </v-row>
          <v-row class="mx-1" no-gutters>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">USERS</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.prospect != undefined
                      ? dashboardCount.prospect.users
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">LOCATIONS</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.prospect != undefined
                      ? dashboardCount.prospect.location
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">SWIPES</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.prospect != undefined
                      ? dashboardCount.prospect.swipes
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <v-divider class="mx-3"></v-divider>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <v-card-text class="pa-0 mt-2" align="center">
                <span class="mx-2" style="font-size: 18px"
                  ><strong> {{ dashboardCount.prospect.prospect_map }}</strong>
                </span>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card height="165px" class="elevation-3 mr-2">
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <v-card-text align="left">
                <v-icon size="75" style="margin-top: -15px" color="primary">
                  mdi-account-group
                </v-icon>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="8">
              <v-card-text align="center">
                <div class="mb-3 black--text" style="font-size: 20px">
                  TRIAL
                </div>
                <div
                  class="my-0 mx-0 black--text"
                  style="font-size: 30px; font-weight: bold"
                >
                  {{
                    dashboardCount.trail != undefined
                      ? dashboardCount.trail.organisation
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row class="mt-n3" no-gutters>
            <v-col cols="12" md="12">
              <v-divider class="mx-3"></v-divider>
            </v-col>
          </v-row>
          <v-row class="mx-1" no-gutters>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">USERS</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.trail != undefined
                      ? dashboardCount.trail.users
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">LOCATIONS</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.trail != undefined
                      ? dashboardCount.trail.location
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">SWIPES</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.trail != undefined
                      ? dashboardCount.trail.swipes
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <v-divider class="mx-3"></v-divider>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <v-card-text class="pa-0 mt-2" align="center">
                <span
                  class="mx-2 my-0 py-0"
                  style="font-size: 18px; text-align: justify"
                >
                  <strong> {{ dashboardCount.trail.trail_map }}</strong>
                </span>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- <v-col cols="12" md="3">
        <v-card height="165px" class="elevation-3 mr-2">
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <v-card-text align="left">
                <v-icon size="75" style="margin-top: -15px" color="primary">
                  mdi-account-alert
                </v-icon>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="8">
              <v-card-text align="center">
                <div class="mb-3 black--text" style="font-size: 20px">
                  ONBOARDED
                </div>
                <div
                  class="my-0 mx-0 black--text"
                  style="font-size: 30px; font-weight: bold"
                >
                  {{
                    dashboardCount.onboard != undefined
                      ? dashboardCount.onboard.organisation
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row class="mt-n3" no-gutters>
            <v-col cols="12" md="12">
              <v-divider class="mx-3"></v-divider>
            </v-col>
          </v-row>
          <v-row class="mx-1" no-gutters>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">USERS</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.onboard != undefined
                      ? dashboardCount.onboard.users
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">LOCATIONS</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.onboard != undefined
                      ? dashboardCount.onboard.location
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="4">
              <v-card-text class="pa-0 ma-0 black--text" align="center">
                <div style="font-size: 13px">SWIPES</div>
                <div style="font-size: 12px; font-weight: bold">
                  {{
                    dashboardCount.onboard != undefined
                      ? dashboardCount.onboard.swipes
                      : 0
                  }}
                </div>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <v-divider class="mx-3"></v-divider>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <v-card-text class="pa-0 mt-2" align="center">
                <span
                  class="mx-2 my-0 py-0"
                  style="font-size: 18px; text-align: justify"
                >
                  <strong> 101 to 150 credits</strong>
                </span>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col> -->
    </v-row>
    <v-row no-gutters class="ma-3 mt-2">
      <v-col cols="12" md="12">
        <v-card class="elevation-0 mr-2">
          <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="12" md="4">
              <v-card-text align="left">
                <h2 style="font-size: 19px">Credits Consumed - last 30 days</h2>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="8">
              <v-toolbar class="mt-3" dense elevation="0">
                <v-text-field
                  prepend-inner-icon="mdi-calendar"
                  label="From"
                  v-model="from_date"
                  disabled
                  class="mt-2 ml-n16"
                  outlined
                  dense
                ></v-text-field>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      :disabled="filterCredits == 'monthly_credits'"
                      v-model="date"
                      label="Credits Till"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      class="mt-2 mx-2"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    scrollable
                    color="orange"
                    min="2021-11-30"
                    :max="tillDates()"
                  >
                  </v-date-picker>
                </v-menu>
                <v-select
                  dense
                  outlined
                  label="Filter By"
                  v-model="filterCredits"
                  :items="filterCreditsItems"
                  class="mt-2 mr-2"
                ></v-select>
                <v-select
                  dense
                  outlined
                  label="Filter By"
                  v-model="organisation_credits"
                  :items="organisation_status_items"
                  class="mt-2 mr-n4"
                  :disabled="filterCredits == 'monthly_credits'"
                ></v-select>
              </v-toolbar>
            </v-col>
          </v-row>
          <div v-if="switchChart1">
            <BarChart
              :chartdata="datacollectionBar"
              :options="optionsgraphBar"
              :count="count"
            />
          </div>
          <div v-if="switchChart2">
            <BarChart
              :chartdata="datacollectionBar2"
              :options="optionsgraphBar2"
              :count="count"
            />
          </div>
          <div v-if="switchChart3">
            <BarChart
              :chartdata="datacollectionBar3"
              :options="optionsgraphBar3"
              :count="count"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BarChart from "@/components/Charts/BarChart.js";
import { API, graphqlOperation } from "aws-amplify";
import {
  PrznceBmsDashboard,
  GlobalDailyScans,
  GlobalyMonthlyScans,
  DashboardDailyScans,
} from "@/graphql/queries.js";
import Overlay from "@/components/Overlay.vue";
import SnackBar from "@/components/SnackBar.vue";
export default {
  components: {
    Overlay,
    BarChart,
    SnackBar,
  },
  data: () => ({
    dashboadType: "bms",
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    dashboardCount: {},
    overlay: false,
    SnackBarComponent: {},
    count: 0,
    switchChart1: true,
    switchChart2: false,
    switchChart3: false,
    filterCredits: "count_credits",
    filterCreditsItems: [
      { text: "CREDITS CONSUMED", value: "count_credits" },
      { text: "AVERAGE CREDITS", value: "avg_credits" },
      { text: "MONTHLY CREDITS", value: "monthly_credits" },
    ],
    organisation_credits: "CUSTOMER",
    organisation_status_items: [
      { text: "ALL", value: "ALL" },
      { text: "CUSTOMERS", value: "CUSTOMER" },
      { text: "PROSPECTS", value: "PROSPECT" },
      // { text: "ACTIVATED", value: "ACTIVATED" },
      // { text: "ONBOARDED", value: "ONBOARD" },
    ],
    datacollectionBar: {
      labels: [],
      datasets: [
        {
          data: [0],
          label: "Total Credits",
          backgroundColor: [],
          borderColor: [],
          fill: true,
        },
        {
          data: [1],
          label: "Face Scan",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
        {
          data: [2],
          label: "QR Scan",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },

        // {
        //   data: [1],
        //   label: "Reswipe",
        //   backgroundColor: [],
        //   borderColor: [],
        //   fill: false,
        // },
        {
          data: [3],
          label: "Proxy",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
        {
          data: [4],
          label: "Forms",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
        {
          data: [5],
          label: "Data Correction",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
        {
          data: [6],
          label: "RFID",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
        {
          data: [7],
          label: "Beacon",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
        {
          data: [8],
          label: "Box",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
        // {
        //   data: [4],
        //   label: "Whatsapp",
        //   backgroundColor: [],
        //   borderColor: [],
        //   fill: false,
        // },

        {
          data: [9],
          label: "Desktop",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
        {
          data: [10],
          label: "Simple",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
        {
          data: [11],
          label: "Live Tracking",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
      ],
    },
    optionsgraphBar: {
      yAxes: [
        {
          ticks: {
            beginAtZero: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      responsive: true,
      maintainAspectRatio: false,
    },
    datacollectionBar2: {
      labels: [],
      datasets: [
        {
          data: [],
          label: "Average Credits",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
      ],
    },
    optionsgraphBar2: {
      yAxes: [
        {
          ticks: {
            beginAtZero: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      scales: {
        xAxes: [
          {
            barPercentage: 0.3,
          },
        ],
      },
      responsive: true,
      maintainAspectRatio: false,
    },

    datacollectionBar3: {
      labels: [],
      datasets: [
        {
          data: [],
          label: "Monthly credits",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
      ],
    },
    optionsgraphBar3: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            barPercentage: 0.2,
          },
        ],
      },
      responsive: true,
      maintainAspectRatio: false,
    },
    from_date: "",
    dashboardItemValue: "ALL",
    dashboardItems: [],
  }),
  created() {
    this.from_date = new Date(
      (Math.floor(new Date(this.date).getTime() / 1000.0) - 2629743) * 1000
    )
      .toISOString()
      .substr(0, 10);
    this.overlay = true;
    this.count = 0;
    this.get_dashboard_list();
    this.get_global_scan_list_customer("CUSTOMER", this.date);
  },
  watch: {
    filterCredits(val) {
      if (val == "avg_credits") {
        this.DailyCountCustomer = [];
        this.DailyCount = [];
        this.datacollectionBar2.labels = [];
        this.overlay = true;
        this.switchChart1 = false;
        this.switchChart2 = true;
        this.switchChart3 = false;
        this.count = 0;
        this.get_global_scan_list_customer2("CUSTOMER", this.date);
      } else if (val == "monthly_credits") {
        this.switchChart1 = false;
        this.switchChart2 = false;
        this.switchChart3 = true;
        this.overlay = true;
        this.count = 0;
        this.getMonthlyCredits();
      } else {
        this.DailyCountCustomer = [];
        this.DailyCount = [];
        this.datacollectionBar.labels = [];
        this.switchChart1 = true;
        this.switchChart2 = false;
        this.switchChart3 = false;
        this.overlay = true;
        this.count = 0;
        this.get_global_scan_list_customer("CUSTOMER", this.date);
      }
    },
    organisation_credits(val) {
      if (this.filterCredits == "count_credits") {
        this.count = 0;
        this.DailyCountCustomer = [];
        this.DailyCount = [];
        this.datacollectionBar.labels = [];
        if (val != "ALL") {
          this.get_global_scan_list_customer(val, this.date);
        } else {
          this.get_global_scan_list(this.date);
        }
      } else if (this.filterCredits == "avg_credits") {
        this.count = 0;
        this.DailyCountCustomer = [];
        this.DailyCount = [];
        this.datacollectionBar2.labels = [];
        if (val != "ALL") {
          this.get_global_scan_list_customer2(val, this.date);
        } else {
          this.get_global_scan_list2(this.date);
        }
      }
    },
    async date(val) {
      this.$refs.menu.save(this.date);
      this.menu = false;
      this.from_date = new Date(
        (Math.floor(new Date(val).getTime() / 1000.0) - 2629743) * 1000
      )
        .toISOString()
        .substr(0, 10);
      this.count = 0;
      this.DailyCountCustomer = [];
      this.DailyCount = [];
      this.datacollectionBar.labels = [];
      this.DailyCountCustomer = [];
      this.DailyCount = [];
      this.datacollectionBar2.labels = [];
      if (this.filterCredits == "count_credits") {
        if (this.organisation_credits == "ALL") {
          await this.get_global_scan_list(val);
        } else {
          await this.get_global_scan_list_customer(
            this.organisation_credits,
            val
          );
        }
      } else {
        if (this.organisation_credits == "ALL") {
          await this.get_global_scan_list2(val);
        } else {
          await this.get_global_scan_list_customer2(
            this.organisation_credits,
            val
          );
        }
        // this.get_global_scan_list_customer2(this.organisation_credits, val);
      }
    },
  },
  methods: {
    tillDates() {
      let today = new Date();
      return (
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate()
      );
    },
    getCustomChart() {
      this.$refs.menu.save(this.date);
      this.menu = false;
      // this.get_global_scan_list_customer("CUSTOMER", this.date);
    },
    async get_dashboard_list() {
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(PrznceBmsDashboard, {
            input: {},
          })
        );
        this.dashboardCount = JSON.parse(result.data.PrznceBmsDashboard);
        // console.log(this.dashboardCount, "this.dashboardCount");
        this.$forceUpdate();
        this.overlay = false;
      } catch (err) {
        this.overlay = false;
        console.log("error", err);
      }
    },
    async get_global_scan_list(date) {
      this.DailyCount = [];
      this.DailyCountCustomer = [];
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(GlobalDailyScans, {
            input: {
              date_time_stamp: Math.floor(new Date(date).getTime() / 1000.0),
            },
          })
        );
        this.DailyCount = JSON.parse(result.data.GlobalDailyScans);
        // console.log("check", this.DailyCount);
        let cameraArray = [];
        var cameraColor = [];
        // var reswipeArray = [];
        var reswipeColor = [];
        var datacorrectionArray = [];
        var datacorrectionColor = [];
        var whatsappColor = [];
        // var whatsappArray = [];
        var proxyArray = [];
        var proxyColor = [];
        var totlColor = [];
        var totlArray = [];
        var beconColor = [];
        var beconArray = [];
        var boxColor = [];
        var faceColor = [];
        var boxArray = [];
        var FaceArray = [];
        var desktoparray = [];
        var desktopColor = [];
        var formsarray = [];
        var formscolor = [];
        var rifidarray = [];
        var rifidcolor = [];
        var simplearray = [];
        var simplecolor = [];
        var livetrackingarray = [];
        var livetrackingcolor = [];
        this.datacollectionBar.datasets[0].data = [];
        this.datacollectionBar.datasets[1].data = [];
        this.datacollectionBar.datasets[2].data = [];
        this.datacollectionBar.datasets[3].data = [];
        this.datacollectionBar.datasets[4].data = [];
        this.datacollectionBar.datasets[5].data = [];
        this.datacollectionBar.datasets[6].data = [];
        this.datacollectionBar.datasets[7].data = [];
        this.datacollectionBar.datasets[8].data = [];
        this.datacollectionBar.datasets[9].data = [];
        this.datacollectionBar.datasets[10].data = [];
        this.datacollectionBar.datasets[11].data = [];
        // this.datacollectionBar.datasets[9].data = [];
        // console.log("DailyCount", this.DailyCount);
        this.datacollectionBar.labels = [];
        for (let i = 0; i < this.DailyCount.length; i++) {
          for (let key in this.DailyCount[i]) {
            // reswipeArray.push(this.DailyCount[i][key].Reswipes);
            cameraArray.push(this.DailyCount[i][key].Camera);
            datacorrectionArray.push(this.DailyCount[i][key].DataCorrections);
            // whatsappArray.push(this.DailyCount[i][key].Whatsapp);
            proxyArray.push(this.DailyCount[i][key].Proxy);
            totlArray.push(this.DailyCount[i][key].totalSwipes);
            beconArray.push(this.DailyCount[i][key].beacon);
            boxArray.push(this.DailyCount[i][key].box);
            desktoparray.push(this.DailyCount[i][key].Desktop);
            FaceArray.push(this.DailyCount[i][key].Face);
            formsarray.push(this.DailyCount[i][key].forms);
            rifidarray.push(this.DailyCount[i][key].Rfid);
            simplearray.push(
              this.DailyCount[i][key].simple == undefined
                ? 0
                : this.DailyCount[i][key].simple
            );
            livetrackingarray.push(
              this.DailyCount[i][key].live == undefined
                ? 10
                : this.DailyCount[i][key].live
            );
            cameraColor.push("#3cba9f");
            reswipeColor.push("#CD5C5C");
            datacorrectionColor.push("#0000FF");
            whatsappColor.push("#800080");
            proxyColor.push("#FFFF00");
            totlColor.push("#002020");
            beconColor.push("#800000");
            boxColor.push("#d90fa6");
            faceColor.push("#F44336");
            desktopColor.push("#FF9800");
            formscolor.push("#4A148C");
            rifidcolor.push("#827717");
            simplecolor.push("#64DD17");
            livetrackingcolor.push("#BF360C");
            this.datacollectionBar.datasets[0].data = totlArray;
            this.datacollectionBar.datasets[0].borderColor = totlColor;
            this.datacollectionBar.datasets[0].backgroundColor = totlColor;

            this.datacollectionBar.datasets[1].data = FaceArray;
            this.datacollectionBar.datasets[1].backgroundColor = faceColor;
            this.datacollectionBar.datasets[1].borderColor = faceColor;

            this.datacollectionBar.datasets[2].data = cameraArray;
            this.datacollectionBar.datasets[2].backgroundColor = cameraColor;
            this.datacollectionBar.datasets[2].borderColor = cameraColor;

            this.datacollectionBar.datasets[3].data = proxyArray;
            this.datacollectionBar.datasets[3].backgroundColor = proxyColor;
            this.datacollectionBar.datasets[3].borderColor = proxyColor;
            // this.datacollectionBar.datasets[3].data = reswipeArray;
            // this.datacollectionBar.datasets[2].backgroundColor = reswipeColor;
            // this.datacollectionBar.datasets[2].borderColor = reswipeColor;

            this.datacollectionBar.datasets[4].data = formsarray;
            this.datacollectionBar.datasets[4].backgroundColor = formscolor;
            this.datacollectionBar.datasets[4].borderColor = formscolor;

            this.datacollectionBar.datasets[5].data = datacorrectionArray;
            this.datacollectionBar.datasets[5].backgroundColor =
              datacorrectionColor;
            this.datacollectionBar.datasets[5].borderColor =
              datacorrectionColor;

            this.datacollectionBar.datasets[6].data = rifidarray;
            this.datacollectionBar.datasets[6].backgroundColor = rifidcolor;
            this.datacollectionBar.datasets[6].borderColor = rifidcolor;
            // this.datacollectionBar.datasets[4].data = whatsappArray;
            // this.datacollectionBar.datasets[4].backgroundColor = whatsappColor;
            // this.datacollectionBar.datasets[4].borderColor = whatsappColor;

            this.datacollectionBar.datasets[7].data = beconArray;
            this.datacollectionBar.datasets[7].backgroundColor = beconColor;
            this.datacollectionBar.datasets[7].borderColor = beconColor;

            this.datacollectionBar.datasets[8].data = boxArray;
            this.datacollectionBar.datasets[8].backgroundColor = boxColor;
            this.datacollectionBar.datasets[8].borderColor = boxColor;

            this.datacollectionBar.datasets[9].data = desktoparray;
            this.datacollectionBar.datasets[9].backgroundColor = desktopColor;
            this.datacollectionBar.datasets[9].borderColor = desktopColor;

            this.datacollectionBar.datasets[10].data = simplearray;
            this.datacollectionBar.datasets[10].backgroundColor = simplecolor;
            this.datacollectionBar.datasets[10].borderColor = simplecolor;

            this.datacollectionBar.datasets[11].data = livetrackingarray;
            this.datacollectionBar.datasets[11].backgroundColor =
              livetrackingcolor;
            this.datacollectionBar.datasets[11].borderColor = livetrackingcolor;

            this.datacollectionBar.labels.push(this.DailyCount[i][key].date);
          }
        }

        this.count = 1;
        this.$forceUpdate();
        this.overlay = false;
      } catch (err) {
        console.log("error", err);
        this.overlay = false;
      }
    },
    async get_global_scan_list_customer(val, date) {
      this.DailyCount = [];
      this.DailyCountCustomer = [];
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(DashboardDailyScans, {
            input: {
              organisation_type: val,
              date_time_stamp: Math.floor(new Date(date).getTime() / 1000.0),
            },
          })
        );
        this.DailyCountCustomer = JSON.parse(result.data.DashboardDailyScans);
        // console.log("this.DailyCountCustomer", this.DailyCountCustomer);
        let cameraArray = [];
        var cameraColor = [];
        // var reswipeArray = [];
        var reswipeColor = [];
        var datacorrectionArray = [];
        var datacorrectionColor = [];
        var whatsappColor = [];
        // var whatsappArray = [];
        var proxyArray = [];
        var proxyColor = [];
        var totlColor = [];
        var totlArray = [];
        var beconColor = [];
        var beconArray = [];
        var boxColor = [];
        var faceColor = [];
        var boxArray = [];
        var FaceArray = [];
        var desktoparray = [];
        var desktopColor = [];
        var formsarray = [];
        var formscolor = [];
        var rfidarray = [];
        var rfidcolor = [];
        var simplearray = [];
        var simplecolor = [];
        var livetrackingarray = [];
        var livetrackingcolor = [];
        this.datacollectionBar.datasets[0].data = [];
        this.datacollectionBar.datasets[1].data = [];
        this.datacollectionBar.datasets[2].data = [];
        this.datacollectionBar.datasets[3].data = [];
        this.datacollectionBar.datasets[4].data = [];
        this.datacollectionBar.datasets[5].data = [];
        this.datacollectionBar.datasets[6].data = [];
        this.datacollectionBar.datasets[7].data = [];
        this.datacollectionBar.datasets[8].data = [];
        this.datacollectionBar.datasets[9].data = [];
        this.datacollectionBar.datasets[10].data = [];
        this.datacollectionBar.datasets[11].data = [];

        this.datacollectionBar.labels = [];

        for (let i = 0; i < this.DailyCountCustomer.length; i++) {
          // reswipeArray.push(this.DailyCountCustomer[i].reswipe);
          cameraArray.push(this.DailyCountCustomer[i].camera);
          datacorrectionArray.push(this.DailyCountCustomer[i].datacorrection);
          // whatsappArray.push(this.DailyCountCustomer[i].whatsapp);
          proxyArray.push(this.DailyCountCustomer[i].proxy);
          totlArray.push(this.DailyCountCustomer[i].total_swipes);
          beconArray.push(this.DailyCountCustomer[i].beacon);
          boxArray.push(this.DailyCountCustomer[i].box);
          FaceArray.push(this.DailyCountCustomer[i].face);
          desktoparray.push(this.DailyCountCustomer[i].desktop);
          formsarray.push(this.DailyCountCustomer[i].forms);
          rfidarray.push(this.DailyCountCustomer[i].rfid);
          simplearray.push(
            this.DailyCountCustomer[i].simple == undefined
              ? 0
              : this.DailyCountCustomer[i].simple
          );
          livetrackingarray.push(
            this.DailyCountCustomer[i].live == undefined
              ? 0
              : this.DailyCountCustomer[i].live
          );
          cameraColor.push("#3cba9f");
          reswipeColor.push("#CD5C5C");
          datacorrectionColor.push("#0000FF");
          whatsappColor.push("#800080");
          proxyColor.push("#FFFF00");
          totlColor.push("#002020");
          beconColor.push("#800000");
          boxColor.push("#d90fa6");
          faceColor.push("#F44336");
          desktopColor.push("#FF9800");
          formscolor.push("#4A148C");
          rfidcolor.push("#827717");
          simplecolor.push("#64DD17");
          livetrackingcolor.push("#BF360C");
          this.datacollectionBar.datasets[0].data = totlArray;
          this.datacollectionBar.datasets[0].borderColor = totlColor;
          this.datacollectionBar.datasets[0].backgroundColor = totlColor;

          this.datacollectionBar.datasets[1].data = FaceArray;
          this.datacollectionBar.datasets[1].backgroundColor = faceColor;
          this.datacollectionBar.datasets[1].borderColor = faceColor;

          this.datacollectionBar.datasets[2].data = cameraArray;
          this.datacollectionBar.datasets[2].backgroundColor = cameraColor;
          this.datacollectionBar.datasets[2].borderColor = cameraColor;

          // this.datacollectionBar.datasets[2].data = reswipeArray;
          // this.datacollectionBar.datasets[2].backgroundColor = reswipeColor;
          // this.datacollectionBar.datasets[2].borderColor = reswipeColor;

          this.datacollectionBar.datasets[3].data = proxyArray;
          this.datacollectionBar.datasets[3].backgroundColor = proxyColor;
          this.datacollectionBar.datasets[3].borderColor = proxyColor;

          // this.datacollectionBar.datasets[4].data = whatsappArray;
          // this.datacollectionBar.datasets[4].backgroundColor = whatsappColor;
          // this.datacollectionBar.datasets[4].borderColor = whatsappColor;

          this.datacollectionBar.datasets[4].data = formsarray;
          this.datacollectionBar.datasets[4].backgroundColor = formscolor;
          this.datacollectionBar.datasets[4].borderColor = formscolor;

          this.datacollectionBar.datasets[5].data = datacorrectionArray;
          this.datacollectionBar.datasets[5].borderColor = datacorrectionColor;
          this.datacollectionBar.datasets[5].backgroundColor =
            datacorrectionColor;

          this.datacollectionBar.datasets[6].data = rfidarray;
          this.datacollectionBar.datasets[6].backgroundColor = rfidcolor;
          this.datacollectionBar.datasets[6].borderColor = rfidcolor;

          this.datacollectionBar.datasets[7].data = beconArray;
          this.datacollectionBar.datasets[7].backgroundColor = beconColor;
          this.datacollectionBar.datasets[7].borderColor = beconColor;

          this.datacollectionBar.datasets[8].data = boxArray;
          this.datacollectionBar.datasets[8].backgroundColor = boxColor;
          this.datacollectionBar.datasets[8].borderColor = boxColor;

          this.datacollectionBar.datasets[9].data = desktoparray;
          this.datacollectionBar.datasets[9].backgroundColor = desktopColor;
          this.datacollectionBar.datasets[9].borderColor = desktopColor;

          this.datacollectionBar.datasets[10].data = simplearray;
          this.datacollectionBar.datasets[10].backgroundColor = simplecolor;
          this.datacollectionBar.datasets[10].borderColor = simplecolor;

          this.datacollectionBar.datasets[11].data = livetrackingarray;
          this.datacollectionBar.datasets[11].backgroundColor =
            livetrackingcolor;
          this.datacollectionBar.datasets[11].borderColor = livetrackingcolor;

          this.datacollectionBar.labels.push(this.DailyCountCustomer[i].date);
          this.from_date = this.DailyCountCustomer[0].date;
        }
        // console.log("this.datacollectionBar", this.datacollectionBar);
        this.count = 1;
        this.$forceUpdate();
        this.overlay = false;
      } catch (err) {
        console.log("error", err);
        this.overlay = false;
      }
    },
    async get_global_scan_list2(date) {
      this.DailyCount = [];
      this.DailyCountCustomer = [];
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(GlobalDailyScans, {
            input: {
              date_time_stamp: Math.floor(new Date(date).getTime() / 1000.0),
            },
          })
        );
        this.DailyCount = JSON.parse(result.data.GlobalDailyScans);
        var AvgArray = [];
        var AvgColor = [];
        this.datacollectionBar2.datasets[0].data = [];
        this.datacollectionBar2.labels = [];
        for (let i = 0; i < this.DailyCount.length; i++) {
          for (let key in this.DailyCount[i]) {
            AvgArray.push(
              Number(this.DailyCount[i][key].averageSwipes).toFixed(2)
            );
            AvgColor.push("#00C897");
            this.datacollectionBar2.datasets[0].data = AvgArray;
            this.datacollectionBar2.datasets[0].backgroundColor = AvgColor;
            this.datacollectionBar2.datasets[0].borderColor = AvgColor;
            this.datacollectionBar2.labels.push(this.DailyCount[i][key].date);
          }
        }
        this.count = 1;
        this.$forceUpdate();
        this.overlay = false;
      } catch (err) {
        console.log("error", err);
        this.overlay = false;
      }
    },
    async get_global_scan_list_customer2(val, date) {
      this.DailyCount = [];
      this.DailyCountCustomer = [];
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(DashboardDailyScans, {
            input: {
              organisation_type: val,
              date_time_stamp: Math.floor(new Date(date).getTime() / 1000.0),
            },
          })
        );
        this.DailyCountCustomer = JSON.parse(result.data.DashboardDailyScans);
        var AvgArray = [];
        var AvgColor = [];
        this.datacollectionBar2.datasets[0].data = [];
        this.datacollectionBar2.labels = [];
        // console.log("DailyCountCustomer", this.DailyCountCustomer);
        for (let i = 0; i < this.DailyCountCustomer.length; i++) {
          AvgArray.push(
            Number(this.DailyCountCustomer[i].averageSwipes).toFixed(2)
          );
          AvgColor.push("#00C897");
          this.datacollectionBar2.datasets[0].data = AvgArray;
          this.datacollectionBar2.datasets[0].backgroundColor = AvgColor;
          this.datacollectionBar2.datasets[0].borderColor = AvgColor;
          this.datacollectionBar2.labels.push(this.DailyCountCustomer[i].date);
        }
        this.count = 1;
        this.$forceUpdate();
        this.overlay = false;
      } catch (err) {
        console.log("error", err);
        this.overlay = false;
      }
    },
    async getMonthlyCredits() {
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(GlobalyMonthlyScans, {
            input: {},
          })
        );
        let MonthlyScans = JSON.parse(result.data.GlobalyMonthlyScans);
        var MonthlyArray = [];
        var MonthlyColor = [];
        this.datacollectionBar3.datasets[0].data = [];
        this.datacollectionBar3.labels = [];
        for (let i = 0; i < MonthlyScans.length; i++) {
          MonthlyArray.push(Object.values(MonthlyScans[i])[0]);
          MonthlyColor.push("#FC4F4F");
          this.datacollectionBar3.datasets[0].data = MonthlyArray;
          this.datacollectionBar3.datasets[0].backgroundColor = MonthlyColor;
          this.datacollectionBar3.datasets[0].borderColor = MonthlyColor;
          this.datacollectionBar3.labels.push(Object.keys(MonthlyScans[i])[0]);
        }
        this.count = 1;
        this.$forceUpdate();
        this.overlay = false;
      } catch (err) {
        console.log(err);
        this.overlay = false;
      }
    },
  },
};
</script>

<style></style>
