<template>
  <div class="mt-2">
    <v-row>
      <v-col cols="12" md="12">
        <v-toolbar dense elevation="0" class="mb-2">
          <div class="mr-5"><h3>Dashboard</h3></div>
          <v-radio-group row class="mt-5 ml-n3" dense v-model="dashboadType">
            <v-radio label="BMS" color="primary" value="bms" />
            <v-radio label="SALES" color="primary" value="sales" />
          </v-radio-group>
          <div>
            <v-select
              v-if="dashboadType == 'sales' ? true : false"
              dense
              outlined
              label="Filter By"
              :items="dashboardItems"
              v-model="dashboardItemValue"
              item-text="user_email_id"
              item-value="user_email_id"
              class="mt-7 ml-3"
            ></v-select>
          </div>
        </v-toolbar>
      </v-col>
    </v-row>
    <BmsDashboard v-if="dashboadType == 'bms' ? true : false" />
    <div v-else>
      <SalesDashboard v-if="RenderSalesDashboard" :userObj="userObj" />
    </div>
  </div>
</template>
<script>
import { API, graphqlOperation } from "aws-amplify";
import { ListSalesRep } from "@/graphql/queries.js";
import BmsDashboard from "@/components/Cards/Dashboard/BmsDashboard.vue";
import SalesDashboard from "@/components/Cards/Dashboard/SalesDashboard.vue";

export default {
  components: {
    BmsDashboard,
    SalesDashboard,
  },
  data: () => ({
    dashboadType: "bms",
    dashboardItemValue: "",
    dashboardItems: [],
    userObj: {},
    RenderSalesDashboard: true,
  }),
  created() {
    this.get_sales_reps_list();
  },
  watch: {
    dashboardItemValue(val) {
      this.userObj = this.dashboardItems.filter(
        (item) => item.user_email_id == val
      )[0];
      this.renderSales();
    },
  },
  methods: {
    async get_sales_reps_list() {
      try {
        let result = await API.graphql(
          graphqlOperation(ListSalesRep, {
            input: {
              user_status: "ACTIVE",
            },
          })
        );
        this.dashboardItems = JSON.parse(result.data.ListSalesRep).Items.sort(
          (a, b) => (a.user_email_id > b.user_email_id ? 1 : -1)
        );
        this.dashboardItemValue = this.dashboardItems[0].user_email_id;
        this.$forceUpdate();
      } catch (err) {
        console.log("error", err);
        this.overlay = false;
      }
    },
    renderSales() {
      this.RenderSalesDashboard = false;
      this.$nextTick(() => {
        this.RenderSalesDashboard = true;
      });
    },
  },
};
</script>

<style></style>
